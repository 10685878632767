import { Box, Divider, Grid, SxProps, Typography } from "@mui/material";
import {
  BACKGROUND_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  SENARY_COLOR,
  TERTIARY_COLOR,
} from "../../utilities/colorway";
import ResponsiveAppBar from "../header/ResponsiveAppBar";
import { LogoEmbeddedInput } from "../utils/Icons";

const CommunityPage = () => {
  return (
    <>
      <ResponsiveAppBar title={""} />
      <Box sx={landingContainerStyles}>
        <Typography sx={TypographyStyles} variant="h4" mt={"5%"}>
          Build art and an audience with AI-generated NFTs
        </Typography>
        <Grid
          container
          flexDirection={"column"}
          justifyContent={"center"}
          sx={DetailsContainerStyles}
          spacing={3}
        >
          <Grid item xs={12} sx={TextBodyStyles}>
            <Typography variant="h5" display={"flex"} justifyContent={"center"}>
              Prompt
            </Typography>

            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              mt={2}
              gap={1}
            >
              <Grid item xs={0.5}>
                <LogoEmbeddedInput />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  display={"flex"}
                  justifyContent={"left"}
                  fontSize={"1.2rem"}
                >
                  Crowdsourcing logos or designs for a start-up, twitter profile
                  pics, mutant NFTs, and more
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              mt={2}
              gap={1}
            >
              <Grid item xs={0.5}>
                <LogoEmbeddedInput />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  display={"flex"}
                  justifyContent={"left"}
                  fontSize={"1.2rem"}
                >
                  Built to capture the recent viral wave of AI generated art
                  through a contest format
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Divider sx={DividerStyles} orientation={"horizontal"} />
          </Grid>

          <Grid item xs={12} sx={TextBodyStyles} mt={4}>
            <Typography variant="h5" display={"flex"} justifyContent={"center"}>
              Rewards
            </Typography>

            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              mt={2}
              gap={1}
            >
              <Grid item xs={0.5}>
                <LogoEmbeddedInput />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  display={"flex"}
                  justifyContent={"left"}
                  fontSize={"1.2rem"}
                >
                  Every promptest starts with a description of the users needs,
                  pool amount, and submission fee
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              mt={2}
              gap={1}
            >
              <Grid item xs={0.5}>
                <LogoEmbeddedInput />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  display={"flex"}
                  justifyContent={"left"}
                  fontSize={"1.2rem"}
                >
                  Voting for the best submission to a promptest is done by a
                  hand-picked set of judges (judges will later be elected
                  through a PROMPT DAO)
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              mt={2}
              gap={1}
            >
              <Grid item xs={0.5}>
                <LogoEmbeddedInput />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  display={"flex"}
                  justifyContent={"left"}
                  fontSize={"1.2rem"}
                >
                  The pool amount + all submission fees is split by the winning
                  submission(s), developers, and judges by a 85%, 10%, and 5%
                  split
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Divider sx={DividerStyles} orientation={"horizontal"} />
          </Grid>

          <Grid item xs={12} sx={TextBodyStyles} mt={4}>
            <Typography variant="h5" display={"flex"} justifyContent={"center"}>
              Prompt Club Membership
            </Typography>

            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              mt={2}
              gap={1}
            >
              <Grid item xs={12}>
                <Typography
                  display={"flex"}
                  justifyContent={"left"}
                  fontSize={"1.2rem"}
                >
                  Promptest winners will get special access to
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              mt={2}
              gap={1}
            >
              <Grid item xs={0.5}>
                <LogoEmbeddedInput />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  display={"flex"}
                  justifyContent={"left"}
                  fontSize={"1.2rem"}
                >
                  Registering addresses to usernames
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              mt={2}
              gap={1}
            >
              <Grid item xs={0.5}>
                <LogoEmbeddedInput />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  display={"flex"}
                  justifyContent={"left"}
                  fontSize={"1.2rem"}
                >
                  Leaderboards showing Profit/Loss
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              mt={2}
              gap={1}
            >
              <Grid item xs={0.5}>
                <LogoEmbeddedInput />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  display={"flex"}
                  justifyContent={"left"}
                  fontSize={"1.2rem"}
                >
                  A community gallery seen on the homepage which can be modified
                  with special instructions
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              mt={2}
              gap={1}
            >
              <Grid item xs={0.5}>
                <LogoEmbeddedInput />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  display={"flex"}
                  justifyContent={"left"}
                  fontSize={"1.2rem"}
                >
                  A new type of marketplace
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <CommunityMasonryImageList /> */}
      </Box>
    </>
  );
};

const DividerStyles: SxProps = {
  background: SENARY_COLOR,
  width: "100%",
  height: "0.5rem",
};

const DetailsContainerStyles: SxProps = {
  background: SECONDARY_COLOR,
  padding: "2% 5% 2%",
  mt: "2%",
  mb: "2%",
  width: "80%",
  borderRadius: 3,
  border: "7px solid " + PRIMARY_COLOR,
};

const landingContainerStyles: SxProps = {
  width: "100%",
  minHeight: "100rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: BACKGROUND_COLOR,
};

const TypographyStyles: SxProps = {
  color: "white",
  fontFamily: "oxanium",
};

const TextBodyStyles: SxProps = {
  color: "white",
  fontFamily: "oxanium",
  padding: "1% 2% 2%",
  background: TERTIARY_COLOR,
  borderRadius: 3,
  border: "10px solid " + SENARY_COLOR,
};

export default CommunityPage;
