import { SxProps, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import {
  ContestProps,
  getUserWithdrawableAmount,
  useClaimRewards,
  useDistributeContestRewards,
} from "../../api/contest";
import { useAppStore } from "../../store/appInfo";
import {
  ERROR,
  MONEY_TEXT_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from "../../utilities/colorway";
import { formatBigNumber } from "../utils/helperFunctions/display";
import TransactionStatusButton from "../utils/TransactionStatusButton";

type props = {
  contest: ContestProps;
};

const ContestSummaryContainer = ({ contest }: props) => {
  const [claimableAmount, setClaimableAmount] = useState("");
  const [amount, setAmount] = useState("");
  const rpcURL = useAppStore((state) => state.rpcURL);
  const account = useAppStore((state) => state.account);
  const chainId = useAppStore((state) => state.chainId);

  // Transaction send and state for claiming rewards for a user
  const { state: rewardState, send: claimRewards } = useClaimRewards(
    contest.address
  );

  const { state: distributeState, send: distributeRewards } =
    useDistributeContestRewards(contest.address);
  

  // Wrapper function that requests the amount that they can claim
  const rewardsWrapper = async () => {
    const result = await getUserWithdrawableAmount(
      rpcURL,
      contest.address,
      account
    );
    setAmount(result);
    const formattedRewards = formatBigNumber(chainId, amount, true);
    setClaimableAmount(formattedRewards);
  };
  useEffect(() => {
    rewardsWrapper();
  });

  const mustDistributeRewardsFirst =
    new Date(contest.duration) < new Date() && !contest.rewardsDistributed;

  const distributeHeader =
    "This promptest has ended! Distribute rewards to judges and participants";

  if (mustDistributeRewardsFirst) {
    return (
      <Box
        width={"100%"}
        display="flex"
        justifyContent={"center"}
        mb={5}
        flexDirection="column"
      >
        <Typography
          sx={HeaderStyle}
          display={"flex"}
          justifyContent="center"
          mb={1}
        >
          {distributeHeader}
        </Typography>
        <TransactionStatusButton
          defaultText={"Distribute Rewards"}
          pendingText={"Distributing Rewards..."}
          unsuccessfulText={"Distributing Unsuccessful"}
          successfulText={"Distributing Successful!"}
          defaultStyle={DistributeButtonStyles}
          pendingStyle={DistributeButtonStyles}
          successfulStyle={ButtonSuccessStyles}
          unsuccessfulStyle={ButtonFailStyles}
          state={distributeState}
          onClick={() => distributeRewards({ gasLimit: 10000000 })}
        />
      </Box>
    );
  }

  return claimableAmount.length && amount !== "0" ? (
    <Box
      width={"100%"}
      display="flex"
      justifyContent={"center"}
      mb={5}
      flexDirection="column"
    >
      <Typography
        sx={HeaderStyle}
        display={"flex"}
        justifyContent="center"
        mb={1}
      >
        Congrats! You've won {claimableAmount}
      </Typography>
      <TransactionStatusButton
        defaultText={"Claim " + claimableAmount}
        pendingText={"Claiming Rewards..."}
        unsuccessfulText={"Claiming Unsuccessful"}
        successfulText={"Successfully Claimed!"}
        defaultStyle={ButtonStyles}
        pendingStyle={ButtonStyles}
        successfulStyle={ButtonSuccessStyles}
        unsuccessfulStyle={ButtonFailStyles}
        state={rewardState}
        onClick={() => claimRewards()}
      />
    </Box>
  ) : (
    <div></div>
  );
};

const HeaderStyle: SxProps = {
  color: PRIMARY_TEXT_COLOR,
  fontSize: "1.4rem",
};

const DistributeButtonStyles: SxProps = {
  background: PRIMARY_COLOR,
  alignSelf: "center",
  fontSize: "1.2rem",
  pl: 4,
  pr: 4,
  width: "50%",
  borderRadius: 3,
};

const ButtonStyles: SxProps = {
  background: MONEY_TEXT_COLOR,
  alignSelf: "center",
  fontSize: "1.2rem",
  pl: 4,
  pr: 4,
  width: "50%",
  borderRadius: 3,
};

const ButtonSuccessStyles: SxProps = {
  background: MONEY_TEXT_COLOR,
  alignSelf: "center",
  fontSize: "1.2rem",
  borderRadius: 3,
  pl: 4,
  pr: 4,
  width: "50%",
};

const ButtonFailStyles: SxProps = {
  background: ERROR,
  alignSelf: "center",
  fontSize: "1.2rem",
  borderRadius: 3,
  pl: 4,
  pr: 4,
  width: "50%",
};

export default ContestSummaryContainer;
