import { Chain } from "@usedapp/core";

export const getAddressLink = (explorerUrl: string) => (address: string) =>
  `${explorerUrl}/address/${address}`;

export const getTransactionLink = (explorerUrl: string) => (txnId: string) =>
  `${explorerUrl}/tx/${txnId}`;

export const CantoTestnet: Chain = {
  chainName: "Canto Testnet",
  chainId: 740,
  rpcUrl: "https://eth.plexnode.wtf",
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: "0x85046900310eC53E05143D70b2cF729Dfb10467d",
  multicall2Address: "0xeB0Df33433b58A74281BD22668F05F68A2EC494F",
  blockExplorerUrl: "https://www.nothing.com",
  getExplorerAddressLink: getAddressLink("kovanEtherscanUrl"),
  getExplorerTransactionLink: getTransactionLink("kovanEtherscanUrl"),
};

export const LocalTestnet: Chain = {
  chainName: "Local Testnet",
  chainId: 6969,
  rpcUrl: "http://localhost:8545",
  isTestChain: true,
  isLocalChain: true,
  multicallAddress: "0x282d9f3f7B8CF28682a1bA23fdf349D66FE62e06",
  multicall2Address: "0x2Aa9f40985532c1286fF1E4275D63eE0B48b030e",
  getExplorerAddressLink: getAddressLink(""),
  getExplorerTransactionLink: getTransactionLink(""),
};

export const contestFactoryAddresses: { [chainId: number]: string } = {
  [LocalTestnet.chainId]: "0x50aa45fa70183e8b22e4fEDA7b3E646C73b44B6e",
  [CantoTestnet.chainId]: "0x9b86360dE500C7f0064DE5989dca5a27f8b8BBE8",
};

// export const promptGenerationEndPoint = "https://146.190.217.216:8000/";
export const promptGenerationEndPoint = "https://promptapp.azurewebsites.net/";

export const decimalsToFormatByChainID: { [chainId: number]: [number, string] } = {
  [LocalTestnet.chainId]: [18, "Canto"],
  [CantoTestnet.chainId]: [18, "Canto"],
};
