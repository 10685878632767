import { promptGenerationEndPoint } from "../config/constants";

export const submitAndGeneratePrompt = async (prompt: string) => {
  const data = { prompt: prompt };

  const response = await fetch(promptGenerationEndPoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Access-Control-Request-Headers": "Content-Type, Authorization",
      "Content-Type": "application/json",
      "Referrer-Policy": "unsafe-url"
    },
  });

  const result = await response.json();
  const url: string = result.data;

  return url.startsWith("https://replicate") ? url : "";
};
