import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  SxProps,
  Typography,
} from "@mui/material";
import { SubmissionProps } from "../../api/submissions";
import { useVoteForContest } from "../../api/governance";
import { useAppStore } from "../../store/appInfo";
import {
  MONEY_TEXT_COLOR,
  PRIMARY_COLOR,
  QUATERNARY_COLOR,
  SECONDARY_TEXT_COLOR,
  TERTIARY_COLOR,
  THIRD_TEXT_COLOR,
} from "../../utilities/colorway";
import { useClaimNFT } from "../../api/contest";
import TransactionStatusButton from "../utils/TransactionStatusButton";

type props = {
  submission: SubmissionProps;
  leadingSubmission: boolean;
  sponsor: string;
  votingPeriodOver: boolean;
};

const SubmissionView = ({ submission, leadingSubmission, sponsor, votingPeriodOver }: props) => {
  const { state: voteState, send: vote } = useVoteForContest(
    submission.address
  );
  const { state: claimState, send: claimNFT } = useClaimNFT(submission.address);

  const judges = useAppStore((state) => state.judges);
  const account = useAppStore((state) => state.account);

  const submitter = submission.submissionAccount;
  const userAccount =
    submitter.slice(0, 5) +
    "..." +
    submitter.slice(submitter.length - 5, submitter.length);
  const isJudge = judges.includes(account) && !votingPeriodOver;
  const isSponsor = sponsor === account && account !== submission.owner && votingPeriodOver;

  let spacing = 0;
  if (isJudge && isSponsor) {
    spacing = 5.5;
  } else if (isJudge || isSponsor) {
    spacing = 12;
  }

  return (
    <Card sx={leadingSubmission ? WinningCardStyles : CardStyles}>
      <CardMedia
        component="img"
        height="400"
        width="160"
        image={submission.tokenURI}
        alt="green iguana"
      />
      <CardContent sx={CardDataStyles}>
        {submission.prompt !== "" && (
          <Box display="flex" justifyContent="space-between" marginBottom={1}>
            <Typography sx={PromptStyles}>{submission.prompt}</Typography>
          </Box>
        )}
        <Box display="flex" justifyContent="space-between" mb={0.3}>
          <Typography sx={SubtextStyles}>{userAccount}</Typography>
          <Typography sx={SubtextStyles}>Votes: {submission.votes}</Typography>
        </Box>
        <Grid
          container
          gap={0}
          justifyContent="space-around"
          alignItems={"center"}
        >
          {isJudge && (
            <Grid item xs={spacing}>
              <TransactionStatusButton
                defaultText={"vote"}
                pendingText={"voting..."}
                unsuccessfulText={"try again"}
                successfulText={"Voted!"}
                defaultStyle={ButtonStyles}
                pendingStyle={ButtonStyles}
                successfulStyle={ButtonStyles}
                unsuccessfulStyle={ButtonStyles}
                state={voteState}
                onClick={() => vote(submission.tokenID)}
                />
            </Grid>
          )}
          {isSponsor && (
            <Grid item xs={spacing}>
              <TransactionStatusButton
                defaultText={"claim this nft"}
                pendingText={"claiming..."}
                unsuccessfulText={"try again"}
                successfulText={"claimed!"}
                defaultStyle={ButtonStyles}
                pendingStyle={ButtonStyles}
                successfulStyle={ButtonStyles}
                unsuccessfulStyle={ButtonStyles}
                state={claimState}
                onClick={() => claimNFT(submission.tokenID)}
                />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

const ButtonStyles: SxProps = {
  background: PRIMARY_COLOR,
  alignSelf: "center",
  color: "white",
  fontSize: "0.9rem",
  width: "100%",
};

const CardDataStyles: SxProps = {
  display: "flex",
  flexDirection: "column",
  height: "min-content",
};

const PromptStyles: SxProps = {
  textAlign: "left",
  fontFamily: "oxanium",
  fontSize: "1.2rem",
  color: THIRD_TEXT_COLOR,
};

const SubtextStyles: SxProps = {
  textAlign: "left",
  fontFamily: "oxanium",
  fontSize: "1.2rem",
};

const CardStyles: SxProps = {
  fontFamily: "oxanium",
  width: "100%",
  background: TERTIARY_COLOR,
  border: "10px solid " + QUATERNARY_COLOR,
  borderRadius: 5,
  color: SECONDARY_TEXT_COLOR,
};

const WinningCardStyles: SxProps = {
  fontFamily: "oxanium",
  width: "100%",
  background: TERTIARY_COLOR,
  border: "10px solid " + MONEY_TEXT_COLOR,
  borderRadius: 5,
  color: SECONDARY_TEXT_COLOR,
};

export default SubmissionView;
