import { Box, Divider, Grid, SxProps, Typography } from "@mui/material";
import { ContestProps } from "../../api/contest";
import { useAppStore } from "../../store/appInfo";
import { formatBigNumber } from "../utils/helperFunctions/display";
import "@fontsource/oxanium/800.css";
import { PRIMARY_COLOR } from "../../utilities/colorway";

type props = {
  contest: ContestProps;
};

const ContestDetailsContainer = ({ contest }: props) => {
  const chainId = useAppStore((state) => state.chainId);

  const formattedPoolAmount = formatBigNumber(
    chainId,
    contest.poolAmount,
    true
  );

  return (
    <Box sx={FormControlStyles}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12} justifyContent="center">
          <Box sx={CenteredBoxStyle}>
            <Typography variant="h3" sx={TypographyStyles}>
              {contest.description}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <Divider sx={DividerStyles} />
        </Grid>
        <Grid item xs={4}>
          <Box sx={ColumnTextStyle}>
            <Typography variant="h5" sx={TypographyStyles}>
              Promptest Pool
            </Typography>
            <Typography variant="h6" sx={TypographyStyles}>
              {formattedPoolAmount}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={ColumnTextStyle}>
            <Typography variant="h5" sx={TypographyStyles}>
              Deadline
            </Typography>
            <Typography variant="h6" sx={TypographyStyles}>
              {contest.duration}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={ColumnTextStyle}>
            <Typography variant="h5" sx={TypographyStyles}>
              Community Submissions
            </Typography>
            <Typography variant="h6" sx={TypographyStyles}>
              {contest.numberSubmissions}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormControlStyles: SxProps = {
  padding: "5% 5% 2%",
  width: "70%",
};

const TypographyStyles: SxProps = {
  color: "white",
  fontFamily: "oxanium",
  textAlign: "center",
};

const CenteredBoxStyle: SxProps = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
};

const ColumnTextStyle: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const DividerStyles: SxProps = {
  background: PRIMARY_COLOR,
  borderBottomWidth: "5px",
};

export default ContestDetailsContainer;
